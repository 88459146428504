import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const BookingSuccess = () => {
	return (
		<>
			<Header />
			<div className="content success-page-cont mt-50 mb-50">
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-lg-6">
							<div className="card success-card">
								<div className="card-body">
									<div className="success-cont">
										<i className="fas fa-check"></i>
										<h3>Your booking has been booked successfully!</h3>
										<p style={{ textAlign: 'center' }}>
											Please check your inbox or spam folder for your booking confirmation. If you
											have any questions, please feel free to contact us {/*either*/} on{' '}
											<b>
												<a href="mailto:medsolutions@sirkkaltd.com">email</a>
											</b>{' '}
											{/* or call us at <u>0330 088 2538</u>. */}
										</p>
										<Link to="/" className="btn btn-primary view-inv-btn">
											Go to Home
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};

export default BookingSuccess;
