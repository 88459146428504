import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { BookingProvider } from '@sirkka-health/booking-system-ui';
import { useEffect, useState } from 'react';

import config from './config';
import './App.css';

// Utils and Context
import ScrollTop from './components/ScrollTop';
import AllContext from './context/AllContext';
import ProtectedRoute from './components/ProtectedRoute';
import CookieConsent from './components/CookieConsent';
import Referral from './components/Referral';

// Pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import Login from './pages/Login';
import BusinessLogin from './pages/BusinessLogin';
import ResetPassword from './pages/ResetPassword';
import Register from './pages/Register';
import VerifyAccount from './pages/CustomerPortal/VerifyAccount';
import CustomerDashboard from './pages/CustomerPortal/CustomerDashboard';
import MedicalRecords from './pages/CustomerPortal/MedicalRecords';
import Profile from './pages/CustomerPortal/Profile';
import Invoices from './pages/CustomerPortal/Invoices';
import Subscription from './pages/CustomerPortal/Subscription';
import MyHousehold from './pages/CustomerPortal/MyHousehold';
import FaqListing from './pages/FAQS/FaqListing';
import PrivacyPolicy from './pages/Policy/Privacy';
import RefundPolicy from './pages/Policy/Refund';
import LegalStatement from './pages/Policy/Legal';
import News from './pages/News';
import NewsPage from './pages/News/Content';
import Membership from './pages/Membership';
import MembershipCorporate from './pages/Membership/CorporateCTA';
import Services from './pages/Services/';
import ScreeningDiagnostics from './pages/ScreeningDiagnostics';
import ServicePage from './pages/Services/ServicePage';
import Products from './pages/Products';
import ProductPage from './pages/Products/ProductPage';
import Doctor from './pages/Doctor';
import TreatmentsAbroad from './pages/TreatmentsAbroad';
import OccupationalHealth from './pages/OccupationalHealth';
import HealthTestsAndSpecialists from './pages/HealthTestsAndSpecialists';
import GiftCards from './pages/GiftCards';
import BookingSuccess from './pages/BookingSuccess';
import ShoppingCart from './pages/ShoppingCart';
import Cart from './pages/Cart';
import BookingCompletion from './pages/BookingCompletion';
import Checkout from './pages/Checkout';
import CheckoutSubscription from './pages/CheckoutSubscription';
import PaymentSuccess from './pages/CheckoutSubscription/Stripe/Complete';
import PersonalDetails from './pages/PersonalDetails';
import Business from './pages/Business';
import NewBooking from './pages/CustomerPortal/NewBooking/Appointments';
import RegisterDelegateAccount from './pages/CustomerPortal/RegisterDelegateAccount';
import NotFound from './pages/NotFound';
import EventPage from './pages/GroupTesting';
import TreatmentsAbroadPage from './pages/InternationalTreatments';
import WellbeingPage from './pages/Wellbeing';
import ImagingPage from './pages/Imaging';
import MentalHealthPage from './pages/MentalHealth';
import SpecialistPage from './pages/Specialist';
import BusinessSignupStepper from './components/BusinessSignupStepper';
import Appointments from './pages/CustomerPortal/Appointments';

const TagManagerArgs = {
	gtmId: config.REACT_APP_GOOGLE_TAG_MANAGER_ID || '',
};

TagManager.initialize(TagManagerArgs);

function App() {
	const [acceptCookies, setAcceptCookies] = useState<boolean>(false);

	useEffect(() => {
		window.dataLayer.push({ event: 'pageview' });
	}, []);

	useEffect(() => {
		const cookiesAccepted = document.cookie.split('; ').some((row) => row.startsWith('web_user'));
		setAcceptCookies(cookiesAccepted);
	}, [acceptCookies]);

	return (
		<AllContext>
			<BookingProvider>
				<BrowserRouter>
					<Referral />
					<ScrollTop />
					{!acceptCookies && <CookieConsent setAcceptCookies={setAcceptCookies} />}
					<AppRoutes />
				</BrowserRouter>
			</BookingProvider>
		</AllContext>
	);
}

const AppRoutes = () => (
	<Routes>
		<Route path="/" element={<Home />} />
		<Route path="home" element={<Home />} />
		<Route path="register" element={<Register />} />
		<Route path="login" element={<Login />} />
		<Route path="business-login" element={<BusinessLogin />} />
		<Route path="resetpassword" element={<ResetPassword />}>
			<Route path=":resetKey" element={<ResetPassword />} />
		</Route>
		<Route path="verifyaccount" element={<ProtectedRoute component={VerifyAccount} />} />
		<Route path="profile" element={<ProtectedRoute component={Profile} />} />
		<Route path="customer-dashboard" element={<ProtectedRoute component={CustomerDashboard} />} />
		<Route
			path="create-delegate-account"
			element={<ProtectedRoute component={RegisterDelegateAccount} />}
		/>
		<Route
			path="appointments"
			element={
				<ProtectedRoute>
					<Appointments />
				</ProtectedRoute>
			}
		/>
		<Route path="new-booking" element={<ProtectedRoute component={NewBooking} />} />
		<Route path="household" element={<ProtectedRoute component={MyHousehold} />} />
		<Route path="medical-records" element={<ProtectedRoute component={MedicalRecords} />} />
		<Route path="invoices" element={<ProtectedRoute component={Invoices} />} />
		<Route path="subscription" element={<ProtectedRoute component={Subscription} />} />
		<Route path="services" element={<Services />} />
		<Route path="services/:service_name" element={<ServicePage />} />
		<Route path="screening-diagnostics" element={<ScreeningDiagnostics />} />
		<Route path="treatments-abroad-old" element={<TreatmentsAbroad />} />
		<Route path="products" element={<Products />} />
		<Route path="products/:product_key" element={<ProductPage />} />
		{/* <Route path="doctor" element={<Doctor />} /> */}
		<Route path="booking" element={<HealthTestsAndSpecialists />} />
		<Route path="gift-cards" element={<GiftCards />} />
		<Route path="cart" element={<Cart />} />
		<Route path="complete-booking" element={<BookingCompletion />} />
		<Route path="checkout" element={<Checkout />} />
		<Route path="checkout/subscription" element={<CheckoutSubscription />} />
		<Route path="checkout/personal-details" element={<PersonalDetails />} />
		<Route path="checkout/complete" element={<PaymentSuccess />} />
		<Route path="booking/complete" element={<BookingSuccess />} />
		<Route path="faqs" element={<FaqListing />} />
		<Route path="business" element={<Business />} />
		<Route path="privacyPolicy" element={<PrivacyPolicy />} />
		<Route path="refundPolicy" element={<RefundPolicy />} />
		<Route path="legalStatement" element={<LegalStatement />} />
		<Route path="membership" element={<Membership />} />
		<Route path="membership/:membershipType" element={<Membership />} />
		<Route path="membership/corporate" element={<MembershipCorporate />} />
		<Route path="shoppingCart" element={<ShoppingCart />} />
		<Route path="news" element={<News />} />
		<Route path="news/:news_key" element={<NewsPage />} />
		<Route path="contact" element={<Contact />} />
		<Route path="occupational-health" element={<OccupationalHealth />} />
		{/* <Route path="mental-health" element={<MentalHealthPage />} /> */}
		<Route path="specialists" element={<SpecialistPage />} />
		<Route path="events" element={<EventPage />} />
		<Route path="international-treatments" element={<TreatmentsAbroadPage />} />
		<Route path="wellbeing" element={<WellbeingPage />} />
		<Route path="imaging" element={<ImagingPage />} />
		<Route path="notMatch" element={<NotFound />} />
		<Route path="business-signup" element={<BusinessSignupStepper />} />
		<Route path="*" element={<NotFound />} />
	</Routes>
);

export default App;
