import { Link } from 'react-router-dom';

const FooterContact = () => {
	return (
		<>
			<div className="col-xl-3 col-lg-3 col-md-6">
				<div className="footer-contact-info footer-contact-info-3 mb-40 mt-4">
					<div className="footer-title">
						<h3 className="pt-1">Contact us</h3>
					</div>

					<div className="footer-emailing">
						<ul>
							<li>
								<i className="far fa-envelope"></i>
								info@sirkkaltd.com
							</li>
							{/* <li>
								<i className="far fa-clone"></i>0330 088 2538
							</li> */}
							<li>
								<i className="far fa-flag"></i>
								London (Step-free access)
								<br /> 83 Victoria Street, <br />
								London SW1H 0HW
							</li>
							<li>
								<i className="far fa-flag"></i>
								Edinburgh (No step-free access) <br />
								Reception Business Centre, <br /> 21 Lansdowne Crescent, Edinburgh EH12 5EH
							</li>
							<li>
								<i className="far fa-flag"></i>
								Cambridge (No step-free access)
								<br /> Norman House, Cambridge Place, <br />
								Cambridge CB2 1NS
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default FooterContact;
