import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import avatar from './avatar.jpeg';
import styles from './DashboardSidebar.module.scss';

const DashboardSidebar = ({ user, membershipData }) => {
	const { pathname } = useLocation();

	const [points, setPoints] = useState(0);

	useEffect(() => {
		if (membershipData && typeof membershipData.reward_points === 'number') {
			setPoints(membershipData.reward_points);
		} else {
			setPoints(0);
		}
	}, [membershipData]);

	return (
		<>
			<div className={styles.dashboardSidebar}>
				<div className={styles.dashboardWidget}>
					<div className={styles.avatar}>
						<img src={avatar} alt="avatar" />
					</div>
					<div className={styles.user}>
						<h3>
							{user.account_type_id === 2 ? user.company : `${user.first_name} ${user.last_name}`}
						</h3>
						<h4>SIRKK{user.customer_id.toString().padStart(8, '0')}</h4>
					</div>
					<div className={styles.points}>
						<h4>{user.account_type_id === 2 ? 'Total Users' : 'Your Balance'}</h4>
						<h3>{user.account_type_id === 2 ? '2/3 users' : `${points} points`}</h3>
					</div>
					<nav className={styles.dashboardMenu}>
						<ul>
							<li>
								<Link
									to="/customer-dashboard"
									className={pathname === '/customer-dashboard' ? styles.activeLink : ''}
								>
									<i className={`${styles.fas} fas fa-columns`}></i>
									<span>Dashboard</span>
								</Link>
							</li>
							{user.account_type_id === 2 && (
								<>
									<li>
										<Link
											to="/new-booking"
											className={pathname === '/new-booking' ? styles.activeLink : ''}
										>
											<i className={`${styles.fas} fas fa-solid fa-circle-plus`}></i>
											<span>Create new booking</span>
										</Link>
									</li>
								</>
							)}
							{user.account_type_id === 2 && user.delegated === false && (
								<li>
									<Link to="/create-delegate-account?type=business">
										<i className={`${styles.fas} fas fa-user-plus`}></i>
										<span>Create a delegate account</span>
									</Link>
								</li>
							)}
							<li>
								<Link to="/profile" className={pathname === '/profile' ? styles.activeLink : ''}>
									<i className={`${styles.fas} fas fa-user-cog`}></i>
									<span>Profile</span>
								</Link>
								{user.account_type === 'Customer' && (
									<Link
										to="/household"
										className={pathname === '/household' ? styles.activeLink : ''}
									>
										<i className={`${styles.fas} fas fa-badge-dollar`}></i>
										<span>My Household</span>
									</Link>
								)}
							</li>
							<li>
								<Link
									to="/appointments"
									className={pathname === '/appointments' ? styles.activeLink : ''}
								>
									<i className={`${styles.fas} fas fa-calendar`}></i>
									<span>Appointments</span>
								</Link>
							</li>
							<li>
								<Link
									to="/medical-records"
									className={pathname === '/medical-records' ? styles.activeLink : ''}
								>
									<i className={`${styles.fas} fas fa-clipboard`}></i>
									<span>Medical Documents</span>
								</Link>
							</li>
							{/* <li>
                            <Link to="/membership/individual">
                                <i className="fas fa-users"></i>
                                <span>Membership</span>
                            </Link>
                        </li> */}
							<li>
								<Link to="/invoices" className={pathname === '/invoices' ? styles.activeLink : ''}>
									<i className={`${styles.fas} fas fa-file-invoice-dollar`}></i>
									<span>Transactions/Invoices</span>
								</Link>
							</li>
							<li>
								{user.account_type === 'Customer' && (
									<Link
										to="/subscription"
										className={pathname === '/subscription' ? styles.activeLink : ''}
									>
										<i className={`${styles.fas} fas fa-badge-dollar`}></i>
										<span>Subscription</span>
									</Link>
								)}
							</li>
							<li>
								<Link to="/faqs" className={pathname === '/faqs' ? styles.activeLink : ''}>
									<i className={`${styles.fas} fas fa-question`}></i>
									<span>FAQs</span>
								</Link>
							</li>
							{membershipData && membershipData.membership_status === 'active' && (
								<li>
									<Link to="/contact" className={pathname === '/contact' ? 'active-Link' : ''}>
										<i className="fas fa-envelope"></i>
										<span>Get assistance</span>
									</Link>
								</li>
							)}
						</ul>
					</nav>
				</div>
			</div>
		</>
	);
};

export default DashboardSidebar;
