import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import { useForm } from 'react-hook-form';
import { checkLogin } from '../../services/data';
import useAuth from '../../hooks/useAuth';
import showAlert from '../../helpers/alerts';
import AuthenticationFormWrapper from '../Register/AuthenticationFormWrapper';
import sendPasswordResetEmail from '../../helpers/sendPasswordResetEmail';
import { Button } from '@sirkka-health/booking-system-ui';

const LoginArea = () => {
	const { isAuthenticated, userAuthenticated } = useAuth();
	const [loginStatus, setLoginStatus] = useState(isAuthenticated);
	const { register, handleSubmit, watch } = useForm();
	const [resetLogin, toggleResetLogin] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	if (loginStatus) {
		if (location.state && location.state?.from?.pathname !== '/verifyaccount') {
			location.state.from.pathname = '/customer-dashboard';
		}
		navigate(location.state?.from?.pathname || '/customer-dashboard');
	}

	const onSubmit = async (data) => {
		const requestData = {
			...data,
			accountType: 'Business',
		};

		try {
			const response = await checkLogin(requestData);

			if (response.status === 'success') {
				userAuthenticated(response.user, data.remember);
				setLoginStatus(true);
				ReactSession.set('billing_address', response.billingDetails);
			} else if (
				response.status === 'error' &&
				response.message === 'Wrong authentication channel.'
			) {
				// Handle the specific error for wrong authentication channel
				showAlert('You have attempted to login with a personal account on the business portal.');
			} else {
				// Handle other generic login failures
				showAlert('Failed to login. Please check your details and try again.');
			}
		} catch (error) {
			// Handle network or server errors
			showAlert('An error occurred during login. Please try again.');
		}
	};

	const heading = resetLogin ? 'Reset Login' : 'Business Account Login';

	return (
		<AuthenticationFormWrapper>
			<h3 className="text-center mb-60">{heading}</h3>
			<form onSubmit={handleSubmit(onSubmit)}>
				<label htmlFor="name" className="mb-2">
					Email Address <span>**</span>
				</label>
				<input
					{...register('email')}
					required
					id="email"
					type="text"
					placeholder="Enter Email address..."
				/>

				{!resetLogin && (
					<>
						<label htmlFor="pass" className="mb-2">
							Password <span>**</span>
						</label>
						<input
							{...register('password')}
							required
							id="pass"
							type="password"
							placeholder="Enter password..."
						/>
					</>
				)}

				<div className="login-action mb-20 fix">
					{!resetLogin && (
						<span className="log-rem f-left">
							<input {...register('remember')} id="remember" type="checkbox" />
							<label htmlFor="remember">Remember me!</label>
						</span>
					)}

					<span className="forgot-login f-right">
						<span onClick={() => toggleResetLogin(!resetLogin)}>Lost your password?</span>
					</span>
				</div>

				{!resetLogin && (
					<>
						<Button type="submit" label="Login" fullWidth large />
						<div className="or-divide">
							<span>or</span>
						</div>
						<Link to="/register?type=business">
							<Button type="button" label="Register" variant="secondary" fullWidth large />
						</Link>
					</>
				)}

				{resetLogin && (
					<Button
						type="button"
						label="Send reset email"
						fullWidth
						large
						onClick={() => sendPasswordResetEmail(watch('email'))}
					/>
				)}
			</form>
		</AuthenticationFormWrapper>
	);
};

export default LoginArea;
