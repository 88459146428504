import React, { useState, useEffect } from 'react';
import { patchRequest, postRequest } from '../../services/api';
import BusinessDetails from './BusinessDetails';
import ListingType from './ListingType';
import AccountSetup from './AccountSetup';
import PaymentSuccess from './PaymentSuccess';
import MoreBusinessDetails from './MoreBusinessDetails';
import ServiceDetails from './ServiceDetails';
import Completion from './Completion';
import SignupHeader from './SignupHeader';
import SignupFooter from './SignupFooter';
import './BusinessSignupStepper.scss';
import { useNavigate } from 'react-router-dom';

const BusinessSignupStepper = () => {
	const [currentStep, setCurrentStep] = useState(1);
	const [isValid, setIsValid] = useState(false);
	const [backNavigation, setBackNavigation] = useState(true);
	const [formData, setFormData] = useState({
		businessName: '',
		businessType: 'In-Clinic',
		businessDescription: '',
		website: '',
		contactName: '',
		email: '',
		bookingEntityId: '',
		moreBusinessDetails: {
			businessDescription: '',
			businessAddress: '',
			businessPhoneNumber: '',
			tags: [],
			locations: [],
		},
		selectedPlan: {
			planType: '',
			price: '',
			mode: '',
		},
		accountUser: {
			firstName: '',
			lastName: '',
			dob: '',
			email: '',
			password: '',
			confirmPassword: '',
		},
		services: [],
	});

	const navigate = useNavigate();

	useEffect(() => {
		const savedStep = localStorage.getItem('businessRegistrationCurrentStep');
		const savedData = localStorage.getItem('businessRegistrationformData');

		if (savedStep) {
			setCurrentStep(Number(savedStep));
		}

		if (savedData) {
			setFormData(JSON.parse(savedData));
		}
	}, []);

	const saveToLocalStorage = (step, data) => {
		if (step >= 7) {
			localStorage.removeItem('businessRegistrationCurrentStep');
			localStorage.removeItem('businessRegistrationformData');
		} else {
			localStorage.setItem('businessRegistrationCurrentStep', step);
			localStorage.setItem('businessRegistrationformData', JSON.stringify(data));
		}
	};

	const handleNext = async () => {
		if (!isValid) return;

		console.log(currentStep, formData.selectedPlan.planType);

		if (currentStep === 1 && formData.selectedPlan.planType === 'Corporate') {
			localStorage.setItem('businessRegistrationCurrentStep', '1');
			navigate('/register?type=business');
			return;
		}

		if (currentStep === 5 && formData.selectedPlan.planType === 'EntityBasic') {
			const nextStep = 7;
			setCurrentStep(nextStep);
			saveToLocalStorage(nextStep, formData);
			return;
		}

		if (currentStep === 3) {
			// Account setup + Payment
			const payload = {
				businessInfo: {
					businessName: formData.businessName,
					businessTypes: formData.businessType,
					fullAddress: formData.moreBusinessDetails.businessAddress,
					phoneNumber: formData.moreBusinessDetails.businessPhoneNumber,
					businessEmail: formData.email,
					websiteUrl: formData.website,
					latitude: '',
					longitude: '',
					tags: formData.moreBusinessDetails.tags,
					ranking: null,
					description:
						`${formData.businessDescription.trim()} ${formData.moreBusinessDetails.businessDescription.trim()}`.trim() ||
						null,
				},
				accountUser: {
					personalTitle: null,
					title: null,
					fullName: formData.contactName,
					gender: null,
					dob: formData.accountUser.dob,
					phoneNumber: '',
					credentials: null,
					username: formData.accountUser.email,
					password: formData.accountUser.password,
					confirmPassword: formData.accountUser.confirmPassword,
				},
				planType: formData.selectedPlan.planType,
			};

			const response = await postRequest('/auth/business/signup', payload);

			if (response && response.businessUser && response.businessUser.booking_entity_id) {
				const bookingEntityId = response.businessUser.booking_entity_id;
				setFormData((prevData) => ({
					...prevData,
					bookingEntityId: bookingEntityId,
				}));
			}
		}

		if (currentStep === 7) {
			// Finalise adding service + locations
			if (formData.bookingEntityId) {
				const servicesPayload = formData.services.map((service) => ({
					service: {
						serviceName: service.name,
						serviceType: service.serviceType,
						description: service.firstDescription + service.descriptionContinued,
						apptDuration: service.duration,
						apptStackable: true,
						servicePrice: Math.round(service.price * 100),
						serviceStatus: true,
						enableDiscount: service.discountEnabled ? service.discountEnabled : false,
						isPrivate: service.private ? service.private : true,
						serviceCode: service.serviceCode,
						serviceIcon: null,
						bullets: service.bullets,
						firstDescription: service.firstDescription,
						descriptionContinued: service.descriptionContinued,
						displayOrder: null,
						emailTemplate: null,
						questionnaireLink: null,
						tags: service.tags || null,
						disclaimers: service.disclaimers || null,
						bookable: true,
					},
					locations: service.selectedLocations.map((loc) => ({
						locationId: 1, // placeholder
						locationAddress: `${loc.buildingName}, ${loc.street}, ${loc.city}`,
						locationCategoryId: 1, // placeholder
					})),
					entity: {
						bookingEntityId: formData.bookingEntityId,
						bookingEntityName: formData.businessName,
					},
				}));

				const bookingEntityUpdatePayload = {
					description: formData.moreBusinessDetails.businessDescription,
					address: formData.moreBusinessDetails.businessAddress,
					phoneNumber: formData.moreBusinessDetails.businessPhoneNumber,
					tags: formData.moreBusinessDetails.tags,
				};

				await postRequest('/products/create', servicesPayload);
				await patchRequest(
					`/booking-entities/update/${formData.bookingEntityId}`,
					bookingEntityUpdatePayload
				);
			}
		}

		const nextStep = currentStep + 1;
		setCurrentStep(nextStep);
		saveToLocalStorage(nextStep, formData);
	};

	const handleBack = () => {
		const prevStep = currentStep - 1;
		setCurrentStep(prevStep);
		saveToLocalStorage(prevStep, formData);
	};

	const handleChange = (newData) => {
		const updatedData = { ...formData, ...newData };
		setFormData(updatedData);
		saveToLocalStorage(currentStep, updatedData);
	};

	const handleStepClick = (step) => {
		setCurrentStep(step);
		saveToLocalStorage(step, formData);
	};

	const steps = [
		<ListingType
			stepNumber={1}
			key="listingType"
			data={formData}
			onChange={handleChange}
			onNext={handleNext}
			onBack={handleBack}
			setIsValid={setIsValid}
		/>,
		<BusinessDetails
			stepNumber={2}
			key="businessDetails"
			data={formData}
			onChange={handleChange}
			onNext={handleNext}
			onBack={handleBack}
			setIsValid={setIsValid}
		/>,
		<AccountSetup
			stepNumber={3}
			key="accountSetup"
			data={formData}
			onChange={handleChange}
			onNext={handleNext}
			onBack={handleBack}
			setIsValid={setIsValid}
			onPaymentSuccess={handleNext}
		/>,
		<PaymentSuccess stepNumber={4} key="paymentSuccess" />,
		<MoreBusinessDetails
			stepNumber={5}
			key="moreBusinessDetails"
			data={formData}
			onChange={handleChange}
			onNext={handleNext}
			onBack={handleBack}
			setBackNavigate={setBackNavigation}
			setIsValid={setIsValid}
		/>,
		<ServiceDetails
			stepNumber={6}
			key="serviceDetails"
			data={formData}
			onChange={handleChange}
			setIsValid={setIsValid}
		/>,
		<Completion
			stepNumber={7}
			key="completion"
			data={formData}
			setBackNavigate={setBackNavigation}
		/>,
	];

	return (
		<div className="d-flex flex-column min-vh-100">
			<SignupHeader step={currentStep} />
			<div className="flex-grow-1 d-flex flex-column">
				<div className="container-fluid px-4">
					<div className="d-flex flex-column align-items-center">
						<div className="stepper-content">{steps[currentStep - 1]}</div>
					</div>
				</div>
			</div>
			<SignupFooter
				currentStep={currentStep}
				onBack={handleBack}
				onNext={handleNext}
				isBackEnabled={backNavigation}
				isNextEnabled={isValid}
			/>
		</div>
	);
};

export default BusinessSignupStepper;
